import { gql, useQuery } from "@apollo/client";
import {
  GetAdminDashboardCalendarDataQuery,
  GetAdminDashboardCalendarDataQueryVariables,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import clsx from "clsx";
import { CalendarDisplayMode, WeekCalendar } from "components/weekCalendar";
import {
  buildBareCalendarNavData,
  buildCalendarEventEngagementAggregateInfo,
} from "components/weekCalendar/helpers";
import { useMemo } from "react";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { getETDateTimeRangeForAmericasUsers } from "./utils";

const ADMIN_DASHBOARD_CALENDAR_DATA = gql`
  query GetAdminDashboardCalendarData($dateTimeRange: DateTimeRangeInput!) {
    engagementAggregateCalendarEvents(
      dateTimeRange: $dateTimeRange
      includeExDates: false
    ) {
      cacheAge
      aggregates {
        ...BuildCalendarEventEngagementAggregateInfo_EngagementAggregateCalendarEvent
      }
    }
  }
  ${buildCalendarEventEngagementAggregateInfo.fragments
    .engagementAggregateCalendarEvent}
`;

type Props = { className?: string };

export function AdminDashboardCalendar({ className }: Props) {
  const locale = Intl.NumberFormat().resolvedOptions().locale;
  const viewingTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { selectedDate, setSelectedDate } = useAdminDashboardContext();
  const { data } = useQuery<
    GetAdminDashboardCalendarDataQuery,
    GetAdminDashboardCalendarDataQueryVariables
  >(ADMIN_DASHBOARD_CALENDAR_DATA, {
    variables: {
      dateTimeRange: getETDateTimeRangeForAmericasUsers(selectedDate),
    },
    onError: (error) => fetchErrToast("Admin Dashboard Calendar data.", error),
  });

  const engagementAggregateInfos = useMemo(() => {
    return buildCalendarEventEngagementAggregateInfo(
      data?.engagementAggregateCalendarEvents.aggregates ?? []
    );
  }, [data?.engagementAggregateCalendarEvents]);

  const { navData, localizedWeekdays } = useMemo(() => {
    const navData = buildBareCalendarNavData(selectedDate, locale);
    const localizedWeekdays = navData.map((nav) => nav.localizedWeekday);
    return { navData, localizedWeekdays };
  }, [locale, selectedDate]);

  return (
    <div
      className={clsx(
        "relative isolate flex flex-col gap-y-1 w-full h-[70vh] mb-1",
        className
      )}
    >
      <WeekCalendar
        hideEventCount
        locale={locale}
        navData={navData}
        mode24Hour={false}
        className="border-t-0"
        viewDate={selectedDate}
        viewingTimeZone={viewingTimeZone}
        localizedWeekdays={localizedWeekdays}
        displayMode={CalendarDisplayMode.AlwaysMobile}
        eventInfosForDisplay={engagementAggregateInfos}
        setViewDate={(date: Date) => setSelectedDate(date)}
      />

      {data?.engagementAggregateCalendarEvents.cacheAge && (
        <div
          className={clsx(
            "absolute bottom-0 right-2 bg-white/30 backdrop-blur-md rounded-sm px-2 py-1",
            "text-slate-300 hover:text-slate-500 cursor-default"
          )}
        >
          <p className="text-xs">
            Current as of{" "}
            {new Date(
              data?.engagementAggregateCalendarEvents.cacheAge
            ).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
}
