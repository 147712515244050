import { gql } from "@apollo/client";
import { WeekSummary_AdminDashboardFragment } from "@generated/graphql";
import {
  DashboardCard,
  DashboardNumber,
  DashboardSection,
  DashboardTitle,
  ProgressBar,
} from "components/shared/DataVisualization";
import { useEffect, useState } from "react";

WeekSummary.fragments = {
  weekSummary: gql`
    fragment WeekSummary_AdminDashboard on WeeksDetails {
      weeksCohortCount
      weeksStudentCount
      weeksEngagementCount
      weeksTutoringHoursCount
      weeksTutoringHoursCompleted
      weeksTutoringHoursInProgress
      weeksTutoringSessionCount
      weeksTutoringSessionsCompleted
      weeksTutoringSessionsInProgress
    }
  `,
};

type HoveredCard = "tutoringSessions" | "tutoringHours" | "none";

type Props = {
  data?: WeekSummary_AdminDashboardFragment;
  viewingPast: boolean;
};

export function WeekSummary({ data, viewingPast }: Props) {
  const [animate, setAnimate] = useState(true);

  const [hoveredCard, setHoveredCard] = useState<HoveredCard>("none");
  const getNum = (num?: number) => (animate ? 0 : num ? Math.round(num) : 0);

  const {
    weeksCohortCount,
    weeksStudentCount,
    weeksEngagementCount,
    weeksTutoringHoursCount,
    weeksTutoringHoursCompleted,
    weeksTutoringHoursInProgress,
    weeksTutoringSessionCount,
    weeksTutoringSessionsCompleted,
    weeksTutoringSessionsInProgress,
  } = data || {};

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 10);
    return () => clearTimeout(timeout);
  }, []);

  const tutoringSessionsCompletedPercent = getNum(
    weeksTutoringSessionCount && weeksTutoringSessionsCompleted
      ? (weeksTutoringSessionsCompleted / weeksTutoringSessionCount) * 100
      : 0
  );
  const tutoringSessionsInProgressPercent = getNum(
    weeksTutoringSessionCount && weeksTutoringSessionsInProgress
      ? (weeksTutoringSessionsInProgress / weeksTutoringSessionCount) * 100
      : 0
  );
  const tutoringHoursCompletedPercent = getNum(
    weeksTutoringHoursCount && weeksTutoringHoursCompleted
      ? (weeksTutoringHoursCompleted / weeksTutoringHoursCount) * 100
      : 0
  );
  const tutoringHoursInProgressPercent = getNum(
    weeksTutoringHoursCount && weeksTutoringHoursInProgress
      ? (weeksTutoringHoursInProgress / weeksTutoringHoursCount) * 100
      : 0
  );

  return (
    <DashboardSection title="Week Summary">
      <div className="grid grid-cols-12 gap-4 lg:gap-6">
        <DashboardCard gridWidth="third">
          <DashboardNumber numberValue={getNum(weeksEngagementCount)} />
          <DashboardTitle
            title="Engagements"
            tooltip="Number of engagements with scheduled sessions this week"
          />
        </DashboardCard>
        <DashboardCard gridWidth="third">
          <DashboardNumber numberValue={getNum(weeksCohortCount)} />
          <DashboardTitle
            title="Cohorts"
            tooltip="Number of cohorts with scheduled sessions this week"
          />
        </DashboardCard>
        <DashboardCard gridWidth="lastThird">
          <DashboardNumber numberValue={getNum(weeksStudentCount)} />
          <DashboardTitle
            title="Students"
            tooltip="Number of students with scheduled sessions this week"
          />
        </DashboardCard>

        <DashboardCard
          gridWidth="half"
          mouseEnter={() => setHoveredCard("tutoringSessions")}
          mouseLeave={() => setHoveredCard("none")}
        >
          <div className="w-full h-full flex flex-col cursor-pointer gap-y-7">
            <div className="flex items-center">
              <DashboardTitle
                title="Tutoring Sessions"
                place="top"
                className="min-h-[52px]"
                tooltip={
                  <div className="flex flex-col">
                    <span>Number of tutoring sessions scheduled this week</span>
                    {`(${weeksTutoringSessionsCompleted} completed & ${weeksTutoringSessionsInProgress} in progress)`}
                  </div>
                }
              />
              <DashboardNumber
                numberValue={getNum(weeksTutoringSessionCount)}
              />
            </div>
            <ProgressBar
              progress={viewingPast ? 100 : tutoringSessionsCompletedPercent}
              subProgress={tutoringSessionsInProgressPercent}
              showIndicators={hoveredCard === "tutoringSessions"}
            />
          </div>
        </DashboardCard>
        <DashboardCard
          gridWidth="half"
          mouseEnter={() => setHoveredCard("tutoringHours")}
          mouseLeave={() => setHoveredCard("none")}
        >
          <div className="w-full h-full flex flex-col cursor-pointer gap-y-7">
            <div className="flex items-center">
              <DashboardTitle
                title="Tutoring Hours"
                place="top"
                className="min-h-[52px]"
                tooltip={
                  <div className="flex flex-col">
                    <span>Number of tutoring hours scheduled this week</span>
                    {`(${weeksTutoringHoursCompleted} completed & ${weeksTutoringHoursInProgress} in progress)`}
                  </div>
                }
              />
              <DashboardNumber numberValue={getNum(weeksTutoringHoursCount)} />
            </div>
            <ProgressBar
              progress={viewingPast ? 100 : tutoringHoursCompletedPercent}
              subProgress={tutoringHoursInProgressPercent}
              showIndicators={hoveredCard === "tutoringHours"}
            />
          </div>
        </DashboardCard>
      </div>
    </DashboardSection>
  );
}
