import { clsx } from "clsx";
import Image from "next/legacy/image";
import { ReactNode } from "react";

type Props = { children?: ReactNode };

export const ProgressBarIndicator = ({ children }: Props) => (
  <div className="relative flex flex-col items-center justify-start h-full w-full cursor-pointer">
    <div className="flex h-full w-full rounded-full bg-white shadow-outer-progress border z-[10]" />
    <div className="absolute h-fit w-full bottom-[-15px] items-center justify-end z-[15]">
      <Image
        className="object-cover object-center transform rotate-180"
        src="/images/isosceles-triangle.svg"
        alt="triangle"
        width={400}
        height={200}
      />
    </div>
    <div className="flex h-full w-full rounded-full bg-white z-[20] absolute p-[3px]">
      <div
        className={clsx(
          "flex h-full w-full rounded-full z-[20] overflow-hidden",
          "flex-center text-xs bg-slate-700 text-white font-semibold"
        )}
      >
        {children}
      </div>
    </div>
  </div>
);
