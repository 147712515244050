import clsx from "clsx";
import { useEffect, useState } from "react";

const TRANSITION_TIME = 100;
const TRANSITION_STEPS = 10;

type Props = {
  numberValue: number;
  className?: string;
  dollarSign?: boolean;
};

export const DashboardNumber = ({
  numberValue,
  className,
  dollarSign,
}: Props) => {
  const [steps, setSteps] = useState<number>(0);
  const [displayNumberValue, setDisplayNumberValue] =
    useState<number>(numberValue);

  useEffect(() => {
    if (numberValue !== displayNumberValue) {
      const difference = Math.abs(Math.round(displayNumberValue - numberValue));
      setSteps(difference > TRANSITION_STEPS ? TRANSITION_STEPS : difference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberValue]);

  useEffect(() => {
    if (displayNumberValue === numberValue) setSteps(0);
    if (steps === 0 || displayNumberValue === numberValue) return;

    let startNumber = displayNumberValue;
    const stepDuration = Math.round(TRANSITION_TIME / steps);
    const increment = Math.ceil((numberValue - startNumber) / steps);

    const interval = setInterval(() => {
      startNumber += increment;
      const over = increment > 0 && startNumber >= numberValue;
      const under = increment < 0 && startNumber <= numberValue;
      if (over || under) {
        clearInterval(interval);
        startNumber = numberValue;
      }
      setDisplayNumberValue(startNumber);
    }, stepDuration);

    return () => clearInterval(interval);
  }, [steps, displayNumberValue, numberValue]);

  return (
    <div
      className={clsx(
        className,
        "w-full justify-center text-center text-5xl font-bold font-roboto text-slate-700 leading-none"
      )}
    >
      {`${dollarSign ? "$" : ""}${
        numberValue <= 0
          ? numberValue.toLocaleString()
          : displayNumberValue.toLocaleString()
      }`}
    </div>
  );
};
