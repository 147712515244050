import {
  AccountStatus,
  CohortSessionTeacherAttendanceStatus,
} from "@generated/graphql";
import { AttendanceRobot } from "components/shared";
import { DynamicTextArea } from "components/shared/Inputs/DynamicTextArea";
import { StaffAssignmentAvatar } from "components/staffAssignments/StaffAssignmentAvatar";
import { useEffect, useState } from "react";
import { AttendanceRowData } from "sections/EngagementsDashboard/types";
import { AttendanceStatus } from "types/global";
import { AttendanceStatusInput } from "./components";

export type AttendanceUpdate = {
  id: string;
  notes?: string | null;
  status: AttendanceStatus;
};

type Props = {
  mutationLoading: boolean;
  attendanceStatusOptions: AttendanceStatus[];
  attendanceRow: AttendanceRowData<AttendanceStatus>;
  onChange: (update: AttendanceUpdate) => void;
};

export const AttendanceRow = ({
  mutationLoading,
  attendanceStatusOptions,
  attendanceRow: {
    id,
    notes,
    userId,
    userName,
    cohortId,
    userEmail,
    updatedById,
    engagementId,
    attendanceStatus,
    userAccountStatus,
    cohortAssignmentRole,
    statusConditionalElementFn,
  },
  onChange,
}: Props) => {
  const [draftNotes, setDraftNotes] = useState<string | null>(notes || null);
  const [status, setStatus] = useState<AttendanceStatus>(attendanceStatus);
  const [newChanges, setNewChanges] = useState(false);

  useEffect(() => {
    if (mutationLoading) return;
    setNewChanges(false);
  }, [mutationLoading, setNewChanges]);

  useEffect(() => {
    if (!newChanges) return;
    if (mutationLoading) return;

    onChange({
      id,
      notes: draftNotes,
      status,
    });

    setNewChanges(false);
  }, [
    onChange,
    newChanges,
    mutationLoading,
    draftNotes,
    status,
    cohortAssignmentRole,
    id,
  ]);

  return (
    <div className="flex flex-col w-full gap-2 mt-2">
      <div className="flex flex-col lg:flex-row justify-between gap-4">
        <div className="sm:shrink-0 flex-1 grow min-w-[300px]">
          <StaffAssignmentAvatar
            id={userId}
            fullName={userName ?? ""}
            cohortId={cohortId}
            engagementId={engagementId}
            email={userEmail ?? ""}
            isInactive={userAccountStatus === AccountStatus.Inactive}
            actions={null}
          />
        </div>

        <div className="flex w-full flex-1 flex-col items-end justify-between gap-y-2">
          <DynamicTextArea
            value={draftNotes}
            className="w-full"
            onChange={(value) => {
              setDraftNotes(value);
              setNewChanges(true);
            }}
            debouncePending={false}
            mutationLoading={mutationLoading}
            placeholder="Add your note..."
            textAreaClassName="border-gray-400"
            rows={2}
          />

          <div className="flex flex-row-reverse flex-wrap w-full justify-between">
            <div className="flex flex-row items-center justify-end gap-x-6">
              <AttendanceRobot
                isAutomaticallyUpdated={
                  !updatedById &&
                  attendanceStatus !==
                    CohortSessionTeacherAttendanceStatus.Unknown
                }
              />
              <div className="flex items-center">
                <AttendanceStatusInput
                  attendanceRowId={id}
                  status={status}
                  attendanceStatusOptions={attendanceStatusOptions}
                  onChange={(status) => {
                    setStatus(status);
                    setNewChanges(true);
                  }}
                  statusConditionalElementFn={statusConditionalElementFn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
