import {
  APP_DEFAULT_TIME_ZONE,
  getDayDateTimeRangeInTimeZone,
  normalizeToLocalISODate,
} from "@utils/dateTime";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";

/**
 * A little hack to increase cache hits for users in the Americas.
 *
 * Since all classes are based in the US, we can fudge the date range a little bit
 * for users in the Americas.
 * It's not really going to cause an issue artificially shifting the date range of
 * anyone within this part of the world (between PST -0800 and ADT -0300 UTC) to always
 * request Calendar data within the ET date range.
 *
 * But if, say, Anca is using the dashboard in her European timezone, this will allow her
 * (and any other admins outside of the Continental US + Nova Scotia/Puerto Rico) to get
 * the correct date range data for her timezone, carving out a little exception.
 *
 * @param selectedDate
 * @returns
 */
export function getETDateTimeRangeForAmericasUsers(selectedDate: Date): {
  start: number;
  end: number;
} {
  const clientOffsetHours = new Date().getTimezoneOffset() / -60;
  const inTheAmericas = clientOffsetHours >= -8 && clientOffsetHours <= -3;

  if (inTheAmericas) {
    const { start, end } = getDayDateTimeRangeInTimeZone(
      normalizeToLocalISODate(selectedDate),
      APP_DEFAULT_TIME_ZONE
    );
    return {
      start: start.getTime(),
      end: end.getTime(),
    };
  } else {
    return {
      start: startOfDay(selectedDate).getTime(),
      end: endOfDay(selectedDate).getTime(),
    };
  }
}
