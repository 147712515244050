import { gql } from "@apollo/client";
import { SessionCell_DashboardStaffFragment } from "@generated/graphql";
import { SessionReportDetails } from "components/shared/AttendanceGrades/SessionReportDetails";
import { CellProps, Column } from "react-table";
import { ShowSessionReportModalProps } from "../../../types";

sessionCell.fragments = {
  staff: gql`
    fragment SessionCell_DashboardStaff on DashboardStaff {
      cohortSessionId
      sessionReportId
      cohortSessionEndDateTime
      hasSessionReportConcerns
    }
  `,
};

export function sessionCell<
  Fragment extends SessionCell_DashboardStaffFragment,
>(
  openSessionReportModal: (value: ShowSessionReportModalProps) => void
): Column<Fragment> {
  return {
    Header: () => (
      <span className="absolute top-0 left-0 w-full h-full flex flex-center text-center">
        Session Report
      </span>
    ),
    width: 200,
    maxWidth: 200,
    accessor: "sessionReportId",
    Cell: ({
      row: {
        original: {
          sessionReportId,
          cohortSessionId,
          cohortSessionEndDateTime,
          hasSessionReportConcerns,
        },
      },
    }: CellProps<Fragment>) => (
      <SessionReportDetails
        showViewText
        canCreateReport={false}
        hasSessionReport={!!sessionReportId}
        hasConcerns={hasSessionReportConcerns}
        sessionEndDateTime={new Date(cohortSessionEndDateTime).getTime()}
        onViewSessionReport={() => {
          if (cohortSessionId && sessionReportId)
            openSessionReportModal({
              show: true,
              cohortSessionId,
              sessionReportId,
            });
        }}
      />
    ),
  };
}
