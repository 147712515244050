import { Transition } from "@headlessui/react";

type Props = {
  show: boolean;
  className?: string;
  children: React.ReactNode;
};

export const AdminDashboardViewModeTransitionContainer = ({
  show,
  children,
  className,
}: Props) => (
  <Transition
    show={show}
    className={className}
    enter="transition-opacity duration-150"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-0"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
);
