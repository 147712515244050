import { gql } from "@apollo/client";
import {
  HostPresenceCell_TutorDashboardEngagementFragment,
  HostPresenceCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { Badge, HostAttendanceIndicator, Tooltip } from "components/shared";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

hostPresenceCell.fragments = {
  tutorDashboardEvent: gql`
    fragment HostPresenceCell_TutorDashboardEvent on TutorDashboardEvent {
      cohortId
      cacheKey
      endDateTime
      startDateTime
      cohortSessionId
      isStudentExclusionDay
    }
  `,
  tutorDashboardEngagement: gql`
    fragment HostPresenceCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      tracksLiveAttendance
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  HostPresenceCell_TutorDashboardEventFragment,
  unknown,
  HostPresenceCell_TutorDashboardEngagementFragment
>;

export function hostPresenceCell<D extends EventDetails>(): Column<D> {
  return {
    Header: () => (
      <span className="absolute top-0 left-0 w-full h-full flex flex-center text-center">
        Host Presence
      </span>
    ),
    width: 210,
    maxWidth: 210,
    accessor: "cohortId",
    Cell: ({
      row: {
        original: {
          cohortId,
          startDateTime,
          endDateTime,
          isStudentExclusionDay,
          engagement,
          cohortSessionId,
        },
      },
    }: CellProps<D>) => {
      if (!engagement?.tracksLiveAttendance || !cohortSessionId)
        return (
          <div className="flex flex-center">
            <Badge className="bg-gray-200 text-gray-900 text-center">
              UNAVAILABLE
            </Badge>
          </div>
        );

      const badgeNoShow = isStudentExclusionDay && (
        <Tooltip content="This cohort has been identified as a non-calculated student day.">
          <Badge className="bg-purple-200 text-purple-900 text-center leading-3">
            NON-CALCULATED STUDENT DAY
          </Badge>
        </Tooltip>
      );

      const attendanceIndicator = engagement.tracksLiveAttendance && (
        <HostAttendanceIndicator
          cohortId={cohortId}
          cohortSessionStartTime={new Date(startDateTime)}
          cohortSessionEndTime={new Date(endDateTime)}
        />
      );

      return (
        <div className="flex flex-center gap-x-2">
          {badgeNoShow}
          {attendanceIndicator}
        </div>
      );
    },
  };
}
