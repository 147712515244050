import { AbbrTooltip } from "components/shared/AbbrTooltip";
import {
  DashboardNumber,
  DashboardTitle,
} from "components/shared/DataVisualization";

type Props = {
  title: string;
  previousPayPeriodString?: string;
  count: number;
  previousCount: number;
};

export function PaymentCell({
  title,
  previousPayPeriodString,
  count,
  previousCount,
}: Props) {
  return (
    <div className="flex flex-col items-center gap-3">
      <div className="text-sm font-semibold text-slate-600 justify-start text-left self-start">
        {title}
      </div>
      <div className="flex flex-center">
        <DashboardNumber
          numberValue={count}
          dollarSign
          className="text-[25px]"
        />
      </div>
      <div className="flex gap-1 flex-center">
        <DashboardTitle
          title={
            <AbbrTooltip tooltipText={previousPayPeriodString}>
              Previous Pay Period
            </AbbrTooltip>
          }
          titleClassName="text-sm font-medium text-slate-600 text-[15px] text-nowrap mb-[-3px]"
        />
        <DashboardNumber
          numberValue={previousCount}
          className="text-[15px]"
          dollarSign
        />
      </div>
    </div>
  );
}
