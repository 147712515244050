import { gql, useQuery } from "@apollo/client";
import {
  GetCurrentUserQuery,
  MentorDashboardQuery,
  MentorDashboardQueryVariables,
} from "@generated/graphql";
import { breadcrumbs } from "@utils/breadcrumbs";
import {
  APP_DEFAULT_TIME_ZONE,
  ISODate,
  MINUTE_MS,
  getDayDateTimeRangeInTimeZone,
  normalizeToLocalISODate,
  normalizeToZonedISODate,
} from "@utils/dateTime";
import { fetchErrToast } from "@utils/errorLogging";
import { Routes } from "@utils/routes";
import { getInputStyle } from "@utils/styleStrings";
import { useInterval } from "@utils/useInterval";
import { DatePicker } from "antd";
import {
  AddInfractionModal,
  AttendanceScorecardModal,
  Container,
  Icon,
  PageHeader,
  ToggleButton,
} from "components/shared";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { useUserDashboardData } from "contexts/UserDashboardDataProvider";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { EventTimeDetails } from "../components/EventTimeDetails";
import { AttendanceModal, MentorDashboardEngagement } from "./components";
import {
  attendanceCell,
  detailsCell,
  hostPresenceCell,
  nameCell,
  roleCell,
  sessionCell,
} from "./components/cells";
import { MentorTeacherDashboardTeacherAttendanceSubscriptionComponent } from "./mentorTeacherAttendanceSubscription";
import { formatTimeslots } from "./utils";

const GET_MENTOR_DASHBOARD_QUERY = gql`
  query MentorDashboard(
    $userId: ID!
    $showISs: Boolean!
    $dateTimeRange: DateTimeRangeInput!
  ) {
    getMentorDashboard(
      userId: $userId
      showISs: $showISs
      dateTimeRange: $dateTimeRange
    ) {
      id
      endTime
      startTime
      engagements {
        id
        engagementId
        tracksLiveAttendance
        name
        staff {
          id
          ...NameCell_DashboardStaff
          ...RoleCell_DashboardStaff
          ...SessionCell_DashboardStaff
          ...DetailsCell_DashboardStaff
          ...AttendanceCell_DashboardStaff
          ...HostPresenceCell_DashboardStaff
        }
      }
    }
  }
  ${nameCell.fragments.staff}
  ${roleCell.fragments.staff}
  ${sessionCell.fragments.staff}
  ${detailsCell.fragments.staff}
  ${attendanceCell.fragments.staff}
  ${hostPresenceCell.fragments.staff}
`;

type Props = {
  currentUser: NonNullable<GetCurrentUserQuery["currentUser"]>;
};

export const MentorDashboard = ({ currentUser }: Props) => {
  const [teacherName, setTeacherName] = useState("");
  const { setShowISs, showISs } = useUserDashboardData();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [attendanceId, setAttendanceId] = useState<string>("");
  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
  const [openAttendanceScorecard, setOpenAttendanceScorecard] = useState(false);
  const [openInfractionModal, setOpenInfractionModal] = useState(false);
  const [selectedCohortId, setSelectedCohortId] = useState<string | undefined>(
    undefined
  );
  const [selectedEngagementId, setSelectedEngagementId] = useState<
    string | null
  >(null);

  const currentISODateET: ISODate = useMemo(() => {
    return normalizeToZonedISODate(currentDate, APP_DEFAULT_TIME_ZONE);
  }, [currentDate]);

  const [selectedISODateET, setSelectedISODateET] =
    useState<ISODate>(currentISODateET);

  const dateTimeRangeET = useMemo(
    () =>
      getDayDateTimeRangeInTimeZone(selectedISODateET, APP_DEFAULT_TIME_ZONE),
    [selectedISODateET]
  );

  useInterval(() => setCurrentDate(new Date()), MINUTE_MS);

  const { data, loading, refetch } = useQuery<
    MentorDashboardQuery,
    MentorDashboardQueryVariables
  >(GET_MENTOR_DASHBOARD_QUERY, {
    fetchPolicy: "cache-first",
    variables: {
      showISs: showISs,
      userId: currentUser.id,
      dateTimeRange: {
        start: +dateTimeRangeET.start,
        end: +dateTimeRangeET.end,
      },
    },
    onError: (err) => fetchErrToast("this Dashboard data.", err),
  });

  const timeslots = useMemo(() => {
    return data ? formatTimeslots(data.getMentorDashboard, currentDate) : [];
  }, [data, currentDate]);

  const engagementIds = useMemo(() => {
    return data
      ? uniq(
          data.getMentorDashboard.flatMap(
            ({ engagements }) =>
              engagements.map(({ engagementId }) => engagementId) ?? []
          )
        )
      : [];
  }, [data]);

  useEffect(() => {
    refetch({
      userId: currentUser.id,
      showISs: showISs,
      dateTimeRange: {
        start: +dateTimeRangeET.start,
        end: +dateTimeRangeET.end,
      },
    });
  }, [
    currentUser.id,
    refetch,
    showISs,
    selectedISODateET,
    attendanceId,
    dateTimeRangeET,
  ]);

  const renderTimeslots =
    timeslots.length > 0 ? (
      <div className="relative flex flex-col gap-y-4">
        {timeslots.map((timeslot) => (
          <Container
            key={timeslot.timeslot.id}
            className="flex flex-col gap-2 gap-y-4"
          >
            <EventTimeDetails
              status={timeslot.status}
              endTime={timeslot.endTime}
              startTime={timeslot.startTime}
              durationMinutes={timeslot.duration}
              minutesElapsed={timeslot.minutesElapsed}
              minutesRemaining={timeslot.minutesRemaining}
            />
            {timeslot.timeslot.engagements.map((engagement, index) => (
              <MentorDashboardEngagement
                key={`${engagement.id}:${index}`}
                engagement={engagement}
                duration={timeslot.duration}
                startTime={timeslot.startTime}
                setTeacherName={setTeacherName}
                setAttendanceId={setAttendanceId}
                setSelectedUserId={setSelectedUserId}
                setOpenAttendanceModal={setOpenAttendanceModal}
                setOpenInfractionsModal={setOpenInfractionModal}
                setOpenAttendanceScorecard={setOpenAttendanceScorecard}
                setCohortAndEngagementIds={(cohortId, engagementId) => {
                  setSelectedCohortId(cohortId);
                  setSelectedEngagementId(engagementId);
                }}
              />
            ))}
          </Container>
        ))}
      </div>
    ) : (
      <Container>
        <EmptyStateContainer
          loading={loading}
          icon={<Icon icon="cancelCalendar" size={6} />}
          subtitle="You have no active sessions scheduled for today."
          dataName="Active Sessions"
        />
      </Container>
    );

  return (
    <>
      <div className="flex w-full justify-between items-center flex-col sm:flex-row">
        <PageHeader
          breadcrumbs={[
            breadcrumbs.home(),
            {
              name: "Dashboard",
              route: Routes.home,
              current: true,
            },
          ]}
        />

        <ToggleButton
          toggleState={showISs}
          handleToggle={(newState) => setShowISs(newState)}
          label="Show Instructional Support"
        />

        <div className="flex items-center space-x-2">
          <label className="text-sm font-medium text-gray-700 whitespace-nowrap overflow-hidden">
            {selectedISODateET === currentISODateET ? "Today" : "Custom"}
          </label>
          <DatePicker
            onChange={(date) => {
              date &&
                setSelectedISODateET(normalizeToLocalISODate(date.toDate()));
            }}
            defaultValue={dayjs(currentISODateET)}
            format={"MM/DD/YYYY"}
            style={getInputStyle}
            className="flex-1"
            showNow={false}
          />
        </div>
      </div>

      {renderTimeslots}

      <AttendanceScorecardModal
        userId={selectedUserId}
        show={openAttendanceScorecard}
        closeModal={() => {
          setOpenAttendanceScorecard(false);
          setSelectedUserId("");
        }}
      />

      {openAttendanceModal && (
        <AttendanceModal
          show={openAttendanceModal}
          closeModal={() => {
            setOpenAttendanceModal(false);
            setAttendanceId("");
            setTeacherName("");
          }}
          teacherName={teacherName}
          attendanceId={attendanceId}
        />
      )}

      {openInfractionModal && selectedEngagementId && (
        <AddInfractionModal
          teacherId={selectedUserId}
          teacherName={teacherName}
          cohortId={selectedCohortId}
          engagementId={selectedEngagementId}
          show={openInfractionModal}
          closeModal={() => {
            setOpenInfractionModal(false);
            setSelectedCohortId(undefined);
            setSelectedEngagementId(null);
          }}
        />
      )}

      {data && (
        <MentorTeacherDashboardTeacherAttendanceSubscriptionComponent
          engagementIds={engagementIds}
        />
      )}
    </>
  );
};
