import { gql } from "@apollo/client";
import {
  SpecialDayType,
  SpecialEventSummaryTab_SpecialEventDetailsFragment,
} from "@generated/graphql";
import { getLocalizedWeekdays, normalizeToLocalISODate } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { Icon, Link, Tooltip } from "components/shared";
import { Table } from "components/shared/Table";
import { useAuth } from "contexts/AuthProvider";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { AdminMode } from "types/global";
import {
  cohortEventMessageMap,
  engagementEventMessageMap,
  eventToColourMap,
  eventToIconMap,
} from "./constants";
import { SpecialDay } from "./types";
import { getHolidayHeader } from "./utils";

SpecialEventSummaryTab.fragments = {
  holidayData: gql`
    fragment SpecialEventSummaryTab_SpecialEventDetails on SpecialEventDetails {
      byEngagement {
        id
        engagement {
          name
          id
        }
        specialDaysByWeekday {
          isoDate
          specialDays {
            type
            cohorts {
              name
              id
            }
          }
        }
      }
    }
  `,
};

type Props = {
  engagementData: SpecialEventSummaryTab_SpecialEventDetailsFragment["byEngagement"];
};

export function SpecialEventSummaryTab({ engagementData }: Props) {
  const columns = useColumns();

  return (
    <div className="flex flex-col gap-y-1.5">
      <Table
        columns={columns}
        data={engagementData}
        className="max-h-[500px] overflow-y-auto text-start"
        verticalDividers
        showPagination={false}
        emptyIcon={<Icon icon="palmTree" size={8} />}
        dataName={"Special Events"}
        loading={false}
        emptyStateSubtitle={"There are no special events this week."}
      />
      {engagementData.length !== 0 && (
        <div className="flex flex-row gap-x-2 ml-auto text-xs text-slate-700 items-center">
          <span className="text-xs text-slate-500 italic">Icon Key:</span>
          <Icon
            icon={eventToIconMap[SpecialDayType.NoShowDay]}
            size={4}
            color={eventToColourMap[SpecialDayType.NoShowDay]}
          />
          <span>No Show Day</span>
          <span>|</span>
          <Icon
            icon={eventToIconMap[SpecialDayType.CancellationDay]}
            size={4}
            color={eventToColourMap[SpecialDayType.CancellationDay]}
          />
          <span>Cancellation</span>
          <span>|</span>
          <Icon
            icon={eventToIconMap[SpecialDayType.AssessmentDay]}
            size={4}
            color={eventToColourMap[SpecialDayType.AssessmentDay]}
          />
          <span>Assessment Day</span>
        </div>
      )}
    </div>
  );
}

function useColumns<
  T extends
    SpecialEventSummaryTab_SpecialEventDetailsFragment["byEngagement"][number],
>(): Column<T>[] {
  const { setAdminMode, setActiveRoute } = useAuth();
  const { selectedDate } = useAdminDashboardContext();
  const localizedWeekdays = useMemo(
    () => getLocalizedWeekdays(normalizeToLocalISODate(selectedDate)),
    [selectedDate]
  );

  return useMemo(() => {
    const engagementColumn = {
      Header: "Engagement",
      sortType: "string",
      Cell: ({
        row: {
          original: { engagement },
        },
      }: CellProps<T>) => {
        const routeProps = [String(engagement.id)];

        return (
          <Link
            route={Routes.engagement.details}
            routeProps={routeProps}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.engagement.details, routeProps);
            }}
          >
            {engagement.name}
          </Link>
        );
      },
    };

    const weekdayColumns = [1, 2, 3, 4, 5].map((dayNumber) => ({
      Header: getHolidayHeader(localizedWeekdays[dayNumber]),
      disableSortBy: true,
      Cell: ({ row }: CellProps<T>) => {
        const specialDays =
          row.original.specialDaysByWeekday[dayNumber - 1].specialDays;

        if (specialDays.length == 0) return "-";

        return (
          <div className="flex flex-row flex-wrap gap-1 max-w-48">
            {Object.values(groupBy(specialDays, "type")).map(
              (groupedSpecialDays) => {
                const { type } = groupedSpecialDays[0];
                const cohorts = groupedSpecialDays.flatMap(
                  ({ cohorts }) => cohorts
                );

                const tooltip = cohortTooltip({ type, cohorts });

                if (cohorts.length === 0) {
                  return (
                    <div
                      key={type}
                      className={clsx(
                        "flex flex-row flex-nowrap items-center font-semibold gap-x-1",
                        eventToColourMap[type]
                      )}
                    >
                      {tooltip}
                      All
                    </div>
                  );
                }

                return (
                  <ul key={type} className="comma-list flex flex-row flex-wrap">
                    {tooltip}
                    {cohorts.map(({ id, name }) => (
                      <li key={id}>
                        <Link
                          route={Routes.cohort.details}
                          routeProps={[String(id)]}
                          onClick={() => {
                            setAdminMode(AdminMode.Managing);
                            setActiveRoute(Routes.cohort.details, [String(id)]);
                          }}
                          className={eventToColourMap[type]}
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                );
              }
            )}
          </div>
        );
      },
    }));

    return [engagementColumn, ...weekdayColumns];
  }, [setAdminMode, setActiveRoute, localizedWeekdays]);
}

const cohortTooltip = ({
  type,
  cohorts: { length },
}: Pick<SpecialDay, "type" | "cohorts">) => (
  <Tooltip
    content={
      length ? cohortEventMessageMap[type] : engagementEventMessageMap[type]
    }
  >
    <Icon icon={eventToIconMap[type]} size={4} color={eventToColourMap[type]} />
  </Tooltip>
);
