import { gql } from "@apollo/client";
import { HolidaySummaryTab_SpecialEventDetailsFragment } from "@generated/graphql";
import { getLocalizedWeekdays, normalizeToLocalISODate } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link } from "components/shared";
import { Table } from "components/shared/Table";
import { useAuth } from "contexts/AuthProvider";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { AdminMode } from "types/global";
import { getHolidayColor, getHolidayIcon } from "./constants";
import { getHolidayHeader } from "./utils";

HolidaySummaryTab.fragments = {
  holidayData: gql`
    fragment HolidaySummaryTab_SpecialEventDetails on SpecialEventDetails {
      usHolidays {
        id
        isoDate
        holidays
      }
      byOrganization {
        id
        organization {
          name
          id
        }
        holidaysByWeekday {
          id
          isoDate
          holidays
        }
      }
    }
  `,
};

type Props = {
  organizationData: HolidaySummaryTab_SpecialEventDetailsFragment["byOrganization"];
  holidayData: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"];
};

export function HolidaySummaryTab({ organizationData, holidayData }: Props) {
  const columns = useColumns();

  const data = useMemo(() => {
    const usHolidayData = formatUSHolidayRow(holidayData);
    const data = usHolidayData.holidaysByWeekday.some(
      ({ holidays }) => holidays.length > 0
    )
      ? [usHolidayData, ...organizationData]
      : organizationData;

    return data;
  }, [holidayData, organizationData]);

  return (
    <div className="flex flex-col gap-y-1.5">
      <Table
        columns={columns}
        data={data}
        className="max-h-[500px] overflow-y-auto text-start"
        verticalDividers
        showPagination={false}
        emptyIcon={<Icon icon="palmTree" size={8} />}
        dataName={"Holidays"}
        loading={false}
        emptyStateSubtitle={"There are no holidays this week."}
      />
      {data.length !== 0 && (
        <div className="flex flex-row gap-x-2 ml-auto text-xs text-slate-700 items-center">
          <span className="text-xs text-slate-500 italic">Icon Key:</span>
          <Icon
            icon={getHolidayIcon(true)}
            size={4}
            color={getHolidayColor(true)}
          />
          <span>US Holiday</span>
          <span>|</span>
          <Icon
            icon={getHolidayIcon(false)}
            size={4}
            color={getHolidayColor(false)}
          />
          <span>No Tutoring</span>
        </div>
      )}
    </div>
  );
}

const formatUSHolidayRow = (
  data: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"]
): HolidaySummaryRow => ({
  id: "US Holidays",
  holidaysByWeekday: data,
});

type HolidaySummaryRow = {
  id: string;
  organization?: HolidaySummaryTab_SpecialEventDetailsFragment["byOrganization"][number]["organization"];
  holidaysByWeekday: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"];
};

function useColumns<T extends HolidaySummaryRow>(): Column<T>[] {
  const { setAdminMode, setActiveRoute } = useAuth();
  const { selectedDate } = useAdminDashboardContext();
  const localizedWeekdays = useMemo(
    () => getLocalizedWeekdays(normalizeToLocalISODate(selectedDate)),
    [selectedDate]
  );

  return useMemo(() => {
    const organizationColumn: Column<T> = {
      Header: "Organization",
      sortType: "string",
      Cell: ({
        row: {
          original: { organization, id },
        },
      }: CellProps<T>) => {
        if (!organization) return id;

        return (
          <Link
            route={Routes.org.details}
            routeProps={[String(organization.id)]}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.org.details, [String(organization.id)]);
            }}
          >
            {organization.name}
          </Link>
        );
      },
    };

    const weekdayColumns: Column<T>[] = [1, 2, 3, 4, 5].map((dayNumber) => ({
      Header: getHolidayHeader(localizedWeekdays[dayNumber]),
      disableSortBy: true,
      Cell: ({
        row: {
          original: { organization, holidaysByWeekday },
        },
      }: CellProps<T>) => {
        const holidays = holidaysByWeekday[dayNumber - 1]?.holidays ?? [];
        return (
          <div className="flex flex-row flex-nowrap gap-x-1">
            {holidays.length !== 0 && (
              <Icon
                icon={getHolidayIcon(!organization)}
                size={4}
                color={getHolidayColor(!organization)}
              />
            )}
            {holidays.join(", ") || "-"}
          </div>
        );
      },
    }));

    return [organizationColumn, ...weekdayColumns];
  }, [setAdminMode, setActiveRoute, localizedWeekdays]);
}
